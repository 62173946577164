import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    component: function () {
      return import('../views/Home.vue')
    },
  },
  {
    path: '/bookmarks',
    component: function () {
      return import('../views/Bookmarks.vue')
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/register',
    component: function () {
      return import('../views/Register.vue')
    }
  },
  {
    path: '/recoverPassword',
    component: function () {
      return import('../views/RecoverPassword.vue')
    }
  },
  {
    path: '/edit/:id',
    component: function () {
      return import('../views/BookmarkEdit.vue')
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/profile',
    component: function () {
      return import('../views/Profile.vue')
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/misc',
    component: function () {
      return import('../views/Misc.vue')
    },
    meta: {
      requiresAuth: true
    },
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.path == '/') && (store.getters.isAuthenticated)) {
    next({
      path:'/bookmarks',
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path:'/',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
