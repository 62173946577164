<template>

<div id="app">

  <b-container class="fixed-top" fluid>
    <b-row align-v="center" class="p-1 justify-content-between header">
      <b-col cols="auto">
        <router-link to="/bookmarks">
          <img src="logo_majou.svg" class="logo_majou"/>
        </router-link>
      </b-col>
      <b-col v-if="isAuthenticated" class="d-none d-sm-block">
         <BookmarkEasyCreate />
      </b-col>
      <b-col v-if="isAuthenticated" class="d-block d-sm-none">
          <b-button to="/misc" block variant="outline-success">{{ t('new') }}</b-button>
      </b-col>
      <b-col cols="auto" v-if="isAuthenticated">
        <UserStatus v-bind:user="user"/>
        <!-- <CompactMenu @close="hideCompactMenu"/> -->
      </b-col>
    </b-row>
  </b-container>

  <div class="view">
    <router-view/>
  </div>

  <Notification/>

</div>

</template>

<script>

import moment from 'moment'

import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
loadProgressBar({ showSpinner: false })

import Notification from '@/components/Notification.vue'
import UserStatus from '@/components/UserStatus.vue'
import BookmarkEasyCreate from '@/components/BookmarkEasyCreate.vue'

export default {
  components: {
    Notification,
    UserStatus,
    BookmarkEasyCreate,
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated
    },
    user: function() {
      return this.$store.state.user
    },
    isMobile: function() {
      return this.$store.getters.isMobile
    },
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    handleResize: function(event) {
      this.$store.commit('setScreenSize', event.currentTarget.innerWidth)
    },
    fetchUser: function() {
      this.$http.get('/users').then(response => {
        this.$store.commit('setUser', response.data)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    fetchBookmarks: function() {
      this.$http.get('/bookmarks').then(response => {
        this.$store.commit('setBookmarks', response.data)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    fetchFolders: function() {
      this.$http.get('/folders').then(response => {
        this.$store.commit('setFolders', response.data)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
  },
  created: function () {
    this.$http.interceptors.request.use(
      config => {
        const token = this.$store.state.token
        if (token) {
          config.headers.common['Authorization'] = "Bearer "+ token
        }
        return config
      },
      error => {
        return Promise.reject(error);
      }
    );
    this.$http.interceptors.response.use(
      response => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      error => {
        this.$store.commit('logout')
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        return Promise.reject(error);
      }
    );
  },
  mounted () {
    moment.locale(this.$store.state.lang)
    if (this.$store.getters.isAuthenticated) {
      this.fetchUser()
      this.fetchBookmarks()
      this.fetchFolders()
    }
    this.$store.commit('setScreenSize', window.innerWidth)
    window.addEventListener('resize', this.handleResize)


  },
}
</script>

<style lang="scss">

@import './assets/css/aller/stylesheet.css';

body {
  background-color: #eee;
  font-size: .9rem;
}

.header {
  height: 70px;
  background-color: #333;
  color: #eee;
}
.view {
  margin-top: 70px;
}
.barmenu {
  cursor: pointer;
  img {
    height: 22px;
  }
}

label {
  font-weight: bold;
}

label::first-letter {
  text-transform: uppercase;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

.container {
  background-color: #f4f4f4;
}
.vertical-space {
  padding-top: 50px;
  padding-bottom: 50px;
}
.nav-link {
  text-transform: capitalize;
}
.logo_majou {
  height: 55px;
}

@media only screen and (max-width: 768px) {

  input[type=radio], input[type=checkbox] {
    width: 30px;
    height: 30px;
  }

}

</style>
