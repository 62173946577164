import Vue from 'vue'
import store from './store'
import router from './router'
import moment from 'moment'
import axios from 'axios'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import App from './App.vue'

Vue.config.productionTip = false

Vue.filter('date', function(value, format='LL') {
  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_APIHOST;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
