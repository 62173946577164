<template>
  <div class="d-inline">
    <router-link to="/profile">
        <b-button>
          <img height="22" src="man.svg"/>
          {{ user.username }}
        </b-button>
    </router-link>
  </div>
</template>

<script>

export default {
  props: [
    'user'
  ],
}
</script>

<style scoped lang="scss">

img {
  margin-right: .3em;
}

</style>
