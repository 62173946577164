<template>
  <div id="notif" v-if="notif">{{ notif }}</div>
</template>

<script>
export default {
  name: 'Notification',
  computed: {
    notif () {
      return this.$store.state.notif
    },
  },
}
</script>

<style scoped lang="scss">

#notif {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  padding: 1em 2em;
  box-shadow: 0 0 10px 1px #999;
  background-color: #ee7;
  color: #333;
  text-align: center;
}

#notif::first-letter {
  text-transform: uppercase;
}
</style>
