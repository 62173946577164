export function findInTree(data, id) {
  var retour = undefined
  for(let i=0; i<data.length && retour === undefined;++i) {
    if (data[i].id == id)
      retour = data[i]
    else if (data[i].children.length) {
      retour = findInTree(data[i].children, id)
    }
  }
  return retour
}


export function authtoken () {
  // Return majou token if present, null otherwise

  var token = null

  if (localStorage.getItem('token') != null) {
    token = localStorage.getItem('token')
  } else if (sessionStorage.getItem('token') != null) {
    token = sessionStorage.getItem('token')
  }

  return token
}

export function crossman(data, prefix='') {
// given a dataset formated as an array of objects with
// id, title and children values
// children is also an array with same formated dataset
  let retour = []
  if (data.length) {
    // sort alphabeticaly folders title
    data.sort(function(a,b) {
      return a.title.localeCompare(b.title)
    })

    for (var i in data) {
      let folder = data[i]
      retour.push({
        id: folder.id,
        title: prefix + folder.title
      })
      if (folder.children.length) {
        retour = retour.concat(
          crossman(folder.children, prefix + folder.title +' / ')
        )
      }
    }
  }
  return retour
}


export function translate(lang, text) {

  const translations = {
    'fr': {
      'all': "Tous",
      'all_folders': "tous les dossiers",
      'already_known': "déjà connu(s)",
      'An error while decoding token': "Le token n'est pas valide",
      'Bad Request': "Mauvaise interrogation",
      'be_a_star': "être une star",
      'bookmark_added': "favori(s) ajouté(s)",
      'bookmark_created': "favori créé",
      'bookmark_deleted': "favori supprimé",
      'bookmark_updated': "favori mis à jour",
      'Browse': "Parcourir",
      'cancel': "annuler",
      'clear': "effacer",
      'close': "fermer",
      'create': "créer",
      'created-': "créé récemment",
      'created+': "créé anciennement",
      'created_at': "créé le",
      'current_thumb': "vignette actuelle",
      'custom_thumb': "adresse de l'icone",
      'delete': "supprimer",
      'edit_folder': "modification du dossier",
      'errors': "erreurs",
      'folder': "dossier",
      'folder_created': "dossier créé",
      'folder_deleted': "dossier supprimé",
      'folder_updated': "dossier mis à jour",
      'folders': "dossiers",
      'general': "général",
      'home': 'accueil',
      'Internal Server Error': 'erreur interne',
      'language': "langue",
      'library': "bibliothèque",
      'login': "connexion",
      'logout': "déconnexion",
      'manage': "gérer",
      'manage_folders': "gérer les dossiers",
      'management': "gestion",
      'miscellaneous': "divers",
      'more_options': "plus options",
      'multiadd_title': "ajout en lot",
      'multiadd_urls': "liste d'adresses",
      'my_account': "mon compte",
      'my_folders': "mes dossiers",
      'more_result': "plus de résultats",
      'new': "nouveau",
      'new_link': "nouveau lien",
      'new_bookmark': "nouveau favori",
      'new_folder': "nouveau dossier",
      'new_sub_folder': "nouveau sous-dossier",
      'no': "non",
      'no_stars': "Aucune star :(",
      'no_stars_text': "Sélectionnez vos favoris STAR dans la bibliothèque pour les afficher ici",
      'Not Found': "introuvable",
      'not_in_folder': "sans dossier",
      'open': "ouvrir",
      'orders': "classements",
      'other': "autre",
      'others': "autres",
      'password': "mot de passe",
      'password_fill_twice': "saisir 2 fois le nouveau mot de passe",
      'password_reseted': "mot de passe réinitialisé, vous allez le recevoir par email",
      'password_updated': "mot de passe mis à jour",
      'profile': "profil",
      "quickadd_title": "bouton ajout rapide",
      "quickadd_help": "faites glisser ces 2 étoiles dans la barre de favoris de votre navigateur. Puis cliquez dessus pour ajouter directement les sites que vous visitez.",
      "quickadd_button": "faites-moi glisser dans la barre de favoris de votre navigateur",
      'recoverPassword': "mot de passe oublié",
      'remember': "mémoriser",
      'register': "créer un compte",
      'rename': "renommer",
      'reset': "réinitialiser",
      'save': "enregistrer",
      'score': "score",
      'score-': "score élevé",
      'score+': "score faible",
      'search': "recherche",
      'search_online_thumbnails': "recherche d'icônes en ligne",
      'select': "sélectionner",
      'select_a_folder': "sélectionner un dossier",
      'select_internet_thumb': "sélectionner une image découverte sur internet",
      'signin': "se connecter",
      'signup': "créer un compte",
      'specials': "spéciaux",
      'submit': "envoyer",
      'temporary_session': "connexion temporaire",
      'The url field is required.': "L'adresse du site est requise",
      'thumbnail': "vignette",
      'title': "titre",
      'title-': "titre z..a",
      'title+': "titre a..z",
      'thumbfile_upload': "envoyer un fichier image",
      'thumbweb_url': "télécharger une image depuis internet en spécifiant son URL",
      'thumbweb_select': "choisir une image web ci-dessous",
      'url': "adresse du site",
      'url not available': "site introuvable",
      'user_updated': "utilisateur mis à jour",
      'unparent': "déplacer à la racine",
      'Unprocessable Entity': "Traitement impossible",
      'username': "nom d'utilisateur",
      'visited_at': "visité le",
      'visited-': "visité récemment",
      'visited+': "visité anciennement",
      'waiting': "en attente",
      'without': "sans",
      'yes': "oui",
    }
  }

  if (translations[lang] === undefined)
    return text
  else {
    let t = translations[lang][text]
    return (t !== undefined) ? t : text
  }

}
