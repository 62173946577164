import Vue from 'vue'
import Vuex from 'vuex'

import { translate } from '../assets/helper.js'
import { findInTree } from '../assets/helper.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token : localStorage.getItem('token') || sessionStorage.getItem('token') || null,
    lang: 'fr',
    notif: '',
    user: {},
    currentFolderId: undefined,
    currentOrder: 'created-',
    bookmarks: [],
    folders: [],
    query: '',
    screenSize: undefined,
  },
  getters: {
    t: state => {
      return function(s) {
        return translate(state.lang, s)
      }
    },
    isAuthenticated: state => {
      return state.token !== null
    },
    isMobile: (state) => {
      return (state.screenSize !== undefined) ? state.screenSize < 820 : false
    },
    getBookmark: (state) => (id) => {
      for (let i in state.bookmarks) {
        if(state.bookmarks[i].id === id) {
          return state.bookmarks[i]
        }
      }
    },
    getFolder: (state) => (id) => {
      return findInTree(state.folders, id)
    },
  },
  mutations: {
    setNotif (state, data) {
      state.notif = data
    },
    setUser (state, user) {
      state.user = user;
    },
    setQuery (state, s) {
      state.query = s;
    },
    setCurrentOrder (state, order) {
      state.currentOrder = order;
    },
    setCurrentFolderId(state, folder_id) {
      state.currentFolderId = folder_id;
    },
    setBookmarks (state, bookmarks) {
      state.bookmarks = bookmarks;
    },
    setFolders (state, folders) {
      state.folders = folders
    },
    login (state, data) {
      if (data.remember) {
        localStorage.setItem('token', data.token)
        sessionStorage.removeItem('token')
        //document.cookie = "token="+ data.token +";secure;samesite=strict;"
      } else {
        sessionStorage.setItem('token', data.token)
        localStorage.removeItem('token')
        //document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;"
      }
      state.token = data.token
    },
    logout (state) {
      sessionStorage.removeItem('token')
      localStorage.removeItem('token')
      state.token = null
    },
    addBookmark (state, bookmark) {
      state.bookmarks.push(bookmark)
    },
    replaceBookmark (state, bookmark) {
      for (let i in state.bookmarks) {
        if(state.bookmarks[i].id === bookmark.id) {
          state.bookmarks.splice(i, 1, bookmark)
        }
      }
    },
    removeBookmark (state, bookmark_id) {
      for (let i in state.bookmarks) {
        if(state.bookmarks[i].id === bookmark_id) {
          state.bookmarks.splice(i, 1)
        }
      }
    },
    addFolder (state, folder) {
      state.folders.push(folder)
    },
    setScreenSize (state, size) {
      state.screenSize = size
    },
  },
  actions: {
    updateNotif: function (context, msg) {
      context.commit('setNotif', msg)
      setTimeout(function(){
        context.commit('setNotif', '')
      }, 2500);
    },
    handleError: function (context, error) {
      let errorText = context.getters.t(error.statusText)
      if (error.data.message !== undefined) {
        errorText += ' - '+ context.getters.t(error.data.message)
      } else {
        for (let e in error.data) {
          if (error.data[e])
          errorText += ' - '+ context.getters.t(error.data[e])
        }
      }
      context.dispatch('updateNotif', errorText)
    },
    logout: function(context) {
      context.commit('setUser', {})
      sessionStorage.removeItem('token')
      localStorage.removeItem('token')
    },
  }
})
