<template>
  <b-form @submit="submit" inline>
    <b-input-group>
      <b-form-input v-model="form.url" :placeholder="t('new_link')" type="url" required></b-form-input>
      <b-input-group-append>
        <b-dropdown split variant="success" @click="submit" :text="t('ok')">
          <b-dropdown-item to="/misc">{{ t('multiadd_title') }}</b-dropdown-item>
        </b-dropdown>
      </b-input-group-append>
    </b-input-group>
  </b-form>
</template>

<script>

export default {
  data: function() {
    return {
      form: {
        url: ''
      },
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    submit: function(evt) {
      evt.preventDefault()
      let data = {
        url: this.form.url,
      }
      this.$http.post('/bookmarks', data).then(response => {
        this.$store.commit('addBookmark', response.data)
        this.$router.push("/edit/"+ response.data.id)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    }
  },
}
</script>

<style scoped lang="scss">

.input-group {
  width: 100%;
  max-width: 600px;
}

</style>
